import { extend } from 'vee-validate';
import ClientService from '../services/client.service';
import UserService from '../services/user.service';
extend('length', {
	validate(value, args) {
		const length = value.length;
		return (length >= args.min || !args.min) && (length <= args.max || !args.max);
	},
	params: ['min', 'max'],
	message: (field, params) => {
		return params.min
			? `${field}は${params.min}文字以上`
			: '' + params.max
			? `${field}は${params.max}文字以下`
			: '';
	},
});
// extend('katakana', {
// 	validate(value) {
// 		const regex = /^[\u30A0-\u30FF\s]+$/;
// 		return regex.test(value);
// 	},
// 	message: 'カタカナで入力してください',
// });
extend('validFormat', {
	validate(value) {
		const passwordRegex = new RegExp(/^[a-zA-Z0-9.?/-]{6,20}$/);
		// const passwordRegex = new RegExp(pattern);
		return passwordRegex.test(value);
	},
	// params: ['pattern'],
	message: '無効なパスワードです',
});

extend('confirmPassword', {
	params: ['target'],
	validate(value, { target }) {
		return value === target;
	},
	message: 'パスワードと確認パスワードが一致しません。',
});
extend('checkExist', {
	params: ['target', 'key', 'ignore'],
	async validate(value, { target, key, ignore }) {
		if (ignore) return true;
		let params = {};
		params[key] = target;
		let [responseUser, error1] = await await UserService.checkExists(params);
		if (error1) {
			console.log({
				responseUser,
				error1,
			});
			return false;
		}
		return !responseUser.exists;
	},
	message: (field, params) => {
		switch (params.key) {
			case 'username':
				return '既に使われているので使用できません。';
			case 'email_address':
				return '既に使われているので使用できません。';
		}
	},
});
extend('validate', {
	params: ['params', 'message', 'callback'],
	async validate(value, { params, callback }) {
		return await callback(...params);
	},
	message: (field, params) => {
		return params.message;
	},
});
