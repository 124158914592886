import { extend } from 'vee-validate';

import { email, required } from 'vee-validate/dist/rules';
import './rules';
extend('email', {
	...email,
	message: '有効なメールアドレスを入力してください',
});

extend('required', {
	...required,
	message: '{_field_}は必須です',
});
